import { Grid, SvgIcon, makeStyles, useTheme, } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { usePageContext } from "./Context/PageContextProvider";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        width: "auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: 400,
    },
    searchBoxContainerWide: {
        maxWidth: "400px",
        width: "100%",
        height: 48,
        border: theme.searchBox?.open?.border ?? "none",
        borderRadius: theme.searchBox?.open?.borderRadius ?? "0px",
        paddingLeft: "8px",
        paddingRight: 8,
        [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            paddingLeft: "10px",
            width: "100%",
        },
    },
    searchIcon: {
        width: "24px",
        height: "24px",
        marginRight: 8,
        "&:hover": {
            cursor: "pointer",
        },
    },
    placeholderPrompt: {
        color: theme.searchBox?.placeholderColor ?? "#323537",
    },
    input: {
        textAlign: "start",
        width: "100%",
        border: "none",
        outline: "none",
        fontSize: "1rem",
        color: theme.searchBox?.inputColor ?? "#323537",
        "&::-ms-clear": {
            display: "none",
            width: 0,
            height: 0,
        },
        fontFamily: theme.typography.fontFamily,
        "&::-webkit-search-cancel-button": {
            "-webkit-appearance": "none",
        },
        "&::placeholder": {
            color: theme.searchBox?.placeholderColor ?? "#323537",
            opacity: 1,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "4px",
        },
    },
}));
const SearchBox = (props) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles({ isOpen });
    const history = useHistory();
    const [inputValue, setInputValue] = useState("");
    // const [suggestions, setSuggestions] = useState([]);
    const { startPageData } = usePageContext();
    useEffect(() => {
        isOpen ? props.onSearchBoxOpen(isOpen) : props.onSearchBoxClose(isOpen);
    }, [isOpen]);
    function goToSearchResultsPage() {
        let pathToSearchResultsPage = "/sokresultat";
        if (startPageData &&
            startPageData.searchResultsPageReference &&
            startPageData.searchResultsPageReference.value) {
            const absolutePath = startPageData.searchResultsPageReference.value.toString();
            pathToSearchResultsPage = getSearchResultsPath(absolutePath);
        }
        function getSearchResultsPath(absolutePath) {
            const actualPathToSearchResultsPage = new URL(absolutePath).pathname;
            let webshopPath = pathToSearchResultsPage !== actualPathToSearchResultsPage
                ? actualPathToSearchResultsPage
                : pathToSearchResultsPage;
            // Ensure the search query is URL-safe
            const encodedSearchQuery = encodeURIComponent(inputValue);
            webshopPath += `?q=${encodedSearchQuery}`;
            return webshopPath;
        }
        history.push(pathToSearchResultsPage);
    }
    const handleCloseIconClick = () => {
        // Clear the search query
        setInputValue("");
        setIsOpen(false);
    };
    const handleEnterKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const inputValue = event.target.value;
            setInputValue(inputValue);
            goToSearchResultsPage();
        }
    };
    const handleOnFocus = () => {
        setIsOpen(true);
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        // fetchAutocompleteOptions(newInputValue);
    };
    // const fetchAutocompleteOptions = async (inputValue: string) => {
    //   if (!inputValue || inputValue.length < 3) {
    //     setSuggestions([]);
    //     return;
    //   }
    //   const response = await FetchAutocompleteSuggestions(inputValue);
    //   const data = await response.json();
    //   setSuggestions(data.suggestions);
    // };
    const hybridInput = isOpen
        ? React.createElement("input", { type: "search", placeholder: props.placeholder, className: classes.input, onKeyDown: (event) => {
                handleEnterKeyDown(event);
            }, onFocus: handleOnFocus, onChange: (event) => handleInputChange(event, event.target.value), value: inputValue })
        : React.createElement("div", { className: classes.placeholderPrompt, onClick: () => setIsOpen(true) }, props.placeholder);
    return (React.createElement(Grid, { container: true, className: clsx(classes.searchBoxContainer, isOpen && classes.searchBoxContainerWide) },
        React.createElement(SvgIcon, { onClick: () => setIsOpen(true), className: classes.searchIcon }, theme.icons?.searchIcon),
        hybridInput,
        isOpen &&
            React.createElement(SvgIcon, { onClick: handleCloseIconClick, className: classes.closeIcon }, theme.icons?.closeIcon)));
};
export default SearchBox;
